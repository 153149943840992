export default (value) => {
  if (typeof value !== 'number') {
    return value;
  }
  const rankings = {
    1: 'st',
    2: 'nd',
    3: 'rd',
    default: 'th',
  };
  return value + rankings[value] || value + rankings.default;
};
